import React, { useEffect, useState } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { RxDotFilled } from "react-icons/rx";
import { BsFillPlayFill } from "react-icons/bs";
import { IoMdRefresh } from "react-icons/io";

const DOGS_API_KEY =
  "live_X2Y7HuU5RH41INJJysTuwYvcsrgXCxhBTSC97s3H3Pn7kSYzlxr6GfVLVK1QEdn2";
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("x-api-key", DOGS_API_KEY);

var requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

export const Slider = () => {
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [autoplay, setAutoplay] = useState(false);

  document.addEventListener("touchstart", handleTouchStart, false);
  document.addEventListener("touchmove", handleTouchMove, false);

  var xDown = null;
  var yDown = null;

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    //The Math.abs() static method returns the absolute value of a number
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        handleNextSlide();
      } else {
        handlePrevSlide();
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  }

  const loadImages = async () => {
    const data = await fetch(
      "https://api.thecatapi.com/v1/images/search?has_breeds=false&size=full&mime_types=jpg&format=json&limit=10",
      requestOptions
    );
    const dataJ = await data.json();
    setSlides(dataJ);
  };

  const handlePrevSlide = () => {
    const isFirstSlide = currentSlideIndex === 0;
    const newIndex = isFirstSlide ? 9 : currentSlideIndex - 1;
    setCurrentSlideIndex(newIndex);
  };

  const handleNextSlide = () => {
    const isLastSlide = currentSlideIndex === 9;
    const newIndex = isLastSlide ? 0 : currentSlideIndex + 1;
    setCurrentSlideIndex(newIndex);
  };

  const handleDotClick = (slideIndex) => {
    setCurrentSlideIndex(slideIndex);
  };

  function LazyBackground({ src }) {
    const [source, setSource] = useState(
      currentSlideIndex === 0
        ? slides[currentSlideIndex].url
        : slides[currentSlideIndex - 1].url
    );

    useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = () => setSource(src);
    }, [src]);

    return (
      <div
        style={{ backgroundImage: `url(${source})` }}
        className="w-full h-full rounded-2xl bg-center bg-cover duration-500 transition-all"
      ></div>
    );
  }
  /* 
  window.onload = function() {
    yourFunction(param1, param2);
  };
  */
  useEffect(() => {
    loadImages();
  }, []);

  const handleAutoplay = () => {
    setInterval(() => {
      setCurrentSlideIndex((currentSlideIndex) => {
        const isLastSlide = currentSlideIndex === 9;
        let newIndex = currentSlideIndex + 1;
        if (isLastSlide) {
          newIndex = 0;
        }
        return newIndex;
      });
    }, 3000);
  };

  return (
    <div className="w-full mt-8 px-4  ">
      {slides.length > 0 ? (
        <>
          <div className="w-full m-auto md:px-20 p-0 flex justify-between items-center gap-x-5 relative group">
            <div className="md:w-1/4 w-full h-[65vh]">
              <LazyBackground src={slides[currentSlideIndex].url} />
            </div>
            <div className="w-1/2 h-[75vh] md:flex hidden">
              <LazyBackground
                src={
                  currentSlideIndex === 9
                    ? slides[0].url
                    : slides[currentSlideIndex + 1].url
                }
              />
            </div>
            <div className="w-1/4 h-[65vh] md:flex hidden">
              <LazyBackground
                src={
                  currentSlideIndex === 8
                    ? slides[0].url
                    : currentSlideIndex === 9
                    ? slides[1].url
                    : slides[currentSlideIndex + 2].url
                }
              />
            </div>
            <div className="arrows left-2">
              <MdOutlineKeyboardArrowLeft
                onClick={handlePrevSlide}
                size={30}
                className="hover:scale-110"
              />
            </div>
            <div className="arrows right-2">
              <MdOutlineKeyboardArrowRight
                onClick={handleNextSlide}
                size={30}
                className="hover:scale-110"
              />
            </div>
          </div>
          <div className="flex top-4 justify-center py-2">
            {slides.map((slide, slideIndex) => (
              <div
                key={slideIndex}
                onClick={() => handleDotClick(slideIndex)}
                className="text-2xl cursor-pointer hover:scale-125"
              >
                <RxDotFilled
                  className="duration-700 transition-all text-lg md:text-3xl"
                  color={
                    currentSlideIndex === slideIndex
                      ? "rgb(0 0 0 / 0.8)"
                      : "rgb(0 0 0 / 0.2)"
                  }
                />
              </div>
            ))}
          </div>
          <div className="flex top-4 justify-center">
            <div
              className={`text-2xl rounded-full p-2 bg-black/80 text-white cursor-pointer transition-all hover:scale-110 ${
                autoplay ? "hidden" : ""
              }`}
            >
              <BsFillPlayFill
                onClick={() => {
                  handleAutoplay();
                  setAutoplay(true);
                }}
                size={30}
                className="hover:scale-110"
              />
            </div>
            <div
              className={`text-2xl rounded-full p-2 bg-black/80 text-white cursor-pointer transition-all hover:scale-110 ${
                !autoplay ? "hidden" : ""
              }`}
            >
              <IoMdRefresh
                onClick={() => {
                  window.location.reload();
                }}
                size={30}
                color="#ffffff"
                className="hover:scale-110"
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
