export const Card = ({title, text, img}) => {
  return (
    <div className="relative flex flex-col justify-center box ">
      <div className="group relative cursor-pointer overflow-hidden bg-white px-4 md:px-6 pt-6 pb-8 shadow-xl shadow-darkMiller/30 ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 mx-auto w-2/3 md:w-full md:max-w-sm rounded-lg h-full">
        <span className="hidden lg:block absolute top-6 left-[50%] -translate-x-[50%] z-0 h-20 w-20 rounded-full bg-iconsMiller transition-all duration-500 group-hover:scale-[14]"></span>
        <div className="relative z-10 mx-auto max-w-md h-full my-auto">
          <span className="flex w-full justify-center  place-items-center rounded-full transition-all duration-300 mb-2 bg-archivio">
            <img src={require(`${img}`)} alt="" className="h-20 w-20"/>
          </span>
          <div className="text-xl font-semibold w-full text-left col-span-2 self-end text-gray-600 transition-all duration-300 lg:group-hover:text-white/90">
            {title}
          </div>
          <div className="my-1 text-gray-600 transition-all duration-300 lg:group-hover:text-white/90 leading-tight">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};
/* export const Card = ({title, text, img}) => {
  return (
    <div className="relative flex flex-col justify-center box ">
      <div className="group relative cursor-pointer overflow-hidden bg-white px-4 md:px-6 pt-6 pb-8 shadow-xl shadow-darkMiller/30 ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 mx-auto w-2/3 md:w-full md:max-w-sm rounded-lg h-[320px] md:h-[280px] lg:h-[320px]">
        <span className="absolute top-6 left-[50%] -translate-x-[50%] z-0 h-12 w-12 md:h-20 md:w-20 rounded-full bg-iconsMiller transition-all duration-500 group-hover:scale-[14]"></span>
        <div className="relative z-10 mx-auto max-w-md h-full my-auto">
          <span className="flex w-full justify-center  place-items-center rounded-full transition-all duration-300  bg-archivio">
            <img src={require(`${img}`)} alt="" className="h-12 w-12 md:h-20 md:w-20"/>
          </span>
          <div className="text-xl font-semibold w-full text-left col-span-2 self-end text-gray-600 transition-all duration-300 group-hover:text-white/90">
            {title}
          </div>
          <div className="mt-1 mb-4 text-gray-600 transition-all duration-300 group-hover:text-white/90 leading-tight">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};
 */